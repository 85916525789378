import Vue from 'vue'
import Vuex from 'vuex'
import querystring from 'querystring'
import registerElements from './elements'
import App from './App.vue'
import storeConfig from './store/store'

registerElements(Vue)

const store = new Vuex.Store(storeConfig)

document.addEventListener('DOMContentLoaded', function () {
  try {
    let app = firebase.app();
    let features = ['auth', 'database', 'messaging', 'storage'].filter(feature => typeof app[feature] === 'function');

    features.forEach(f => {
      try {
        window[f] = app[f]()
      } catch (e) {
        console.error(e)
      }
    })

    // eslint-disable-next-line no-new
    new Vue({
      el: '#app',
      store,
      render: h => h(App)
    })

    if (process.env.NODE_ENV !== 'test') {
      auth.onAuthStateChanged((user) => {
        if (user) {
          database.ref(`players/${user.uid}`).on('value', (snapshot) => {
            const DEFAULT_NAME = 'Guest'
            const userInfo = snapshot.val() || {}
            user.name = userInfo.name || DEFAULT_NAME
            store.dispatch('setPlayer', user)
          })
        } else {
          database.ref(`players/${store.getters.player.id}`).off('value')
          store.dispatch('setPlayer')
          store.dispatch('signInAnonymously')
          store.dispatch('initLogins')
        }
      })
    }
  } catch (e) {
    console.error(e);
  }
})
