<template>
  <div>
    <div class="board-container">
      <div
        class="board"
        v-loading="loading"
      >
        <square
          :key="`${s.file}${s.rank}`"
          v-for="s of squares"
          :piece="s.piece"
          :rank="s.rank"
          :file="s.file"
        />
        <transition name="lean-in">
        <div
          v-if="!gameStarted && !loading"
          class="overlay"
          :class="{ loaded: !loading }"
        >
          <svg-piece
            class="mascot white"
            side="white"
            piece="knight"
          />
          <svg-piece
            class="mascot black"
            side="black"
            piece="knight"
          />
          <button
            class="button white"
            :class="{
              hidden: game && game.white
            }"
            @click="joinTeam('white')"
            data-cy="play-as-white"
          >
            <span>Play as</span>&nbsp;<span>White</span>
          </button>
          <button
            class="button black"
            :class="{
              hidden: game.black
            }"
            @click="joinTeam('black')"
            data-cy="play-as-black"
          >
            <span>Play as</span>&nbsp;<span>Black</span>
          </button>
        </div>
        </transition>
      </div>
    </div>
    <transition name="slide">
      <div class="confirm-move" v-if="unconfirmed">
        <el-button @click="cancelMove" class="my-2 mx-auto">Cancel</el-button>
        <el-button @click="confirmMove" class="my-2 mx-auto btn-confirm" type="primary">Confirm</el-button>
      </div>
    </transition>
  </div>
</template>

<script>
import SvgPiece from "./SvgPiece.vue";
import Piece from "./Piece.vue";
import Square from "./Square.vue";
import { mapActions, mapGetters } from "vuex";

let updating;

export default {
  components: {
    Square,
    SvgPiece
  },
  computed: {
    ...mapGetters([
      "selected",
      "gameStatus",
      "game",
      "gameStarted",
      "player",
      "loading",
      "unconfirmed"
    ]),
    // todo: determine, default to false
    playingAsBlackOnly() {
      if (
        this.player.id === this.game.black &&
        this.player.id !== this.game.white
      ) {
        return true;
      }
      return false;
    },
    board() {
      return this.gameStatus.board;
    },
    squares() {
      return this.board.squares.reduce((board, square) => {
        let row = square.rank - 1;
        if (!this.playingAsBlackOnly) {
          row = 7 - row;
        }
        const col = parseInt(square.file, 18) - 10;
        const position = row * 8 + col;
        board[position] = square;
        return board;
      }, []);
    },
    ranks() {
      return [1, 2, 3, 4, 5, 6, 7, 8];
    },
    files() {
      return "abcdefgh".split("");
    }
  },
  methods: {
    ...mapActions(["joinTeam", "cancelMove", "confirmMove"])
  }
};
</script>

<style scoped>
.board-container {
  --max-board-size: 700px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 0 1em 0;
  max-width: var(--max-board-size);
  max-height: var(--max-board-size);
  width: 100vmin;
}

.board {
  grid-row: 1;
  grid-column: 2;
  --square-size: calc(600px / 8);
  background: rgba(88, 88, 88, 0.05);
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-shadow: 0 1px 2px rgba(22, 22, 22, 0.2);
  position: relative;
  transition: transform 0.4s cubic-bezier(0, 0.99, 0.3, 1);
  user-select: none;
}

.ranks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ranks.reverse {
  flex-direction: column-reverse;
}

.files {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  justify-content: space-around;
}

.hidden {
  visibility: hidden;
}

.overlay {
  top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(233, 233, 233, 0.9);
  z-index: 2;
  padding: 24px;
  display: flex;
  align-items: flex-start;
}

.button {
  color: #333;
  border: none;
  font-size: calc(1em + 1vw);
  font-weight: bold;
  padding: 0.5em 1em;
  box-shadow: 0 0 2px 2px rgba(24, 24, 24, 0.4);
  margin: auto 0 0;
}

.confirm-move {
  display: flex;
  margin-bottom: 1em;;
  justify-content: space-around;
}

.black {
  margin-left: auto;
}

.checkmate {
  flex-grow: 1;
  font-size: 3em;
  color: white;
  text-align: center;
}

.mascot {
  transform: scale(4) translate(0, 0) rotate(10deg);
  margin-left: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.4s ease;
}

@media (max-width: 699px) {
  .mascot {
    margin-top: -25%;
  }
}

.mascot.white {
  transform: scale(10) rotate(25deg) rotateY(180deg);
  margin-left: -50%;
}

.lean-in-enter .mascot.white {
  filter: blur(1px);
  transform: scale(10) rotateY(180deg);
}

.mascot.black {
  left: auto;
  transform: scale(10) rotateZ(-25deg);
}

.lean-in-enter .mascot.black {
  transform: scale(10);
}
</style>
