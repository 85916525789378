<template>
  <span
    class="square"
    :data-coord="`${file}${rank}`"
    :style="{
      backgroundColor
    }"
    @mousedown.prevent="handlePointerDown"
    @touchstart.prevent="handlePointerDown"
  >
    <span v-if="file === 'a'" class="square-label t0">{{ rank }}</span>
    <piece 
      v-if="piece" 
      :piece="piece" 
      :selected="isSelected" 
      :transform="transform"
    />
    <span v-if="rank === 1" class="square-label r0 b0">{{ file }}</span>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Piece from "./Piece.vue";

const bg = {
  selected: "#55bbee",
  previousSrc: "#ffedab",
  previousDest: "#ffed4b"
};

function coords(evt) {
  let clientX = evt.clientX;
  let clientY = evt.clientY;

  const touchPoint =
    (evt.touches && evt.touches[0]) ||
    (evt.changedTouches && evt.changedTouches[0]);
  if (touchPoint) {
    clientX = touchPoint.clientX;
    clientY = touchPoint.clientY;
  }

  return {
    clientX,
    clientY
  };
}

export default {
  components: {
    Piece
  },
  props: {
    piece: {
      type: Object,
      default: null
    },
    rank: {
      type: Number,
      required: true
    },
    file: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      startX: 0,
      startY: 0
    };
  },
  computed: {
    ...mapGetters({
      currentMove: "currentMove",
      moves: "moves",
      previousMove: "previousMove",
      selected: "selected"
    }),
    transform() {
      return {
        x: this.x,
        y: this.y
      };
    },
    isSelected() {
      return this.selected === `${this.file}${this.rank}`;
    },
    previousSrc() {
      return this.matches(this.previousMove.src);
    },
    previousDest() {
      return this.matches(this.previousMove.dest);
    },
    backgroundColor() {
      if (this.isSelected) {
        return bg.selected;
      }
      if (this.previousSrc) {
        return bg.previousSrc;
      }
      if (this.previousDest) {
        return bg.previousDest;
      }

      if ("aceg".includes(this.file)) {
        return this.rank % 2 ? "#aaa" : "#fff";
      }

      return this.rank % 2 ? "#fff" : "#aaa";
    }
  },
  methods: {
    ...mapActions(["clickSquare", "selectSquare"]),
    matches(square = {}) {
      const { file, rank } = square;
      return this.file === file && this.rank === rank;
    },
    handlePointerDown(evt) {
      if (this.currentMove !== this.moves.length - 1) {
        return;
      }

      const square = evt.currentTarget.getAttribute("data-coord");

      this.selectSquare(square);

      this.addEventListeners();

      const { clientX, clientY } = coords(evt);
      this.x = 0;
      this.y = 0;
      this.startX = clientX;
      this.startY = clientY;
    },
    handlePointerMove(evt) {
      if (this.isUpdating) return;

      const { clientX, clientY } = coords(evt);

      this.isUpdating = true;
      requestAnimationFrame(() => {
        this.x = clientX - this.startX;
        this.y = clientY - this.startY;
        this.isUpdating = false;
      });
    },
    handlePointerUp(evt) {
      const { clientX, clientY } = coords(evt);

      const el = document.elementFromPoint(clientX, clientY);
      const square = el && el.closest(".square");
      const coord = square && square.getAttribute("data-coord");

      // wrap in rAF as there may still be one queued from previous Move event
      requestAnimationFrame(() => {
        this.x = 0;
        this.y = 0;
      });

      this.clickSquare(coord);
      this.removeEventListeners();
    },
    addEventListeners() {
      window.addEventListener("mousemove", this.handlePointerMove);
      window.addEventListener("touchmove", this.handlePointerMove);
      window.addEventListener("mouseup", this.handlePointerUp);
      window.addEventListener("touchend", this.handlePointerUp);
      window.addEventListener("touchcancel", this.handlePointerUp);
    },
    removeEventListeners() {
      window.removeEventListener("mousemove", this.handlePointerMove);
      window.removeEventListener("touchmove", this.handlePointerMove);
      window.removeEventListener("mouseup", this.handlePointerUp);
      window.removeEventListener("touchend", this.handlePointerUp);
      window.removeEventListener("touchcancel", this.handlePointerUp);
    }
  }
};
</script>

<style>
.square {
  width: 12.5%;
  height: 0;
  padding: 6.25% 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  position: relative;
}

.square-label {
  font-size: 80%;
  padding: 1px;
  opacity: 0.5;
  position: absolute;
}

.r0 {
  right: 0;
}

.t0 {
  top: 0;
}

.b0 {
  bottom: 0;
}

.square:hover {
  background: #88ccff !important;
}
</style>
