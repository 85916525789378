<template>
  <div>
    <div class="moveHistory">
      <el-row class="mb-1">
        <el-button
          type="secondary"
          icon="el-icon-d-arrow-left"
          title="First move"
          data-test="firstMove"
          @click.native="setCurrentMove(0)"
          :disabled="currentMove === 0 || moves.length === 0"
        />
        <el-button
          type="secondary"
          icon="el-icon-arrow-left"
          title="Previous Move"
          data-test="prevMove"
          @click.native="setCurrentMove(currentMove - 1)"
          :disabled="currentMove === 0 || moves.length === 0"
        />
        <el-button
          type="secondary"
          icon="el-icon-arrow-right"
          title="Next Move"
          data-test="nextMove"
          @click.native="setCurrentMove(currentMove + 1)"
          :disabled="currentMove === moves.length - 1"
        />
        <el-button
          type="secondary"
          icon="el-icon-d-arrow-right"
          title="Last Move"
          data-test="lastMove"
          @click.native="setCurrentMove(moves.length - 1)"
          :disabled="currentMove === moves.length - 1"
        />
      </el-row>
      <el-button 
        data-test="new-game" 
        type="secondary" 
        @click="newGame"
      >
        New game
      </el-button>
      <el-button
        :disabled="currentMove === 0"
        data-test="start-from-here"
        type="secondary"
        @click="newGameFromCurrent"
      >
        Start from current move
      </el-button>

      <h3 v-if="moves.length > 0">Move History</h3>
      <div class="move-history-list" v-if="moves.length > 0">
        <p 
          v-for="move in groupedMoves" 
          class="move" 
          :key="move.number"
        >
          <small class="turn-number">{{ move.number }}.</small>
          <span
            :data-test="`move-${move.white.index}`"
            :class="{active: move.white.index === currentMove}"
            @click="setCurrentMove(move.white.index)"
          >{{ move.white.pge }}</span>
          <span
            v-if="move.black"
            :data-test="`move-${move.black.index}`"
            :class="{active: move.black.index === currentMove}"
            @click="setCurrentMove(move.black.index)"
          >{{ move.black.pge }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      current: 0
    };
  },
  methods: mapActions(["setCurrentMove", "newGameFromCurrent", "newGame"]),
  computed: {
    ...mapGetters({
      moves: "moves",
      currentMove: "currentMove"
    }),
    turn() {
      return this.moves.length % 2 === 0 ? "White" : "Black";
    },
    groupedMoves() {
      return this.moves.reduce((moveList, move, index) => {
        const moveIndex = Math.floor(index / 2);
        const moveNumber = moveIndex + 1;
        moveList[moveIndex] = moveList[moveIndex] || { number: moveNumber };
        if (index % 2 === 0) {
          moveList[moveIndex].white = {
            pge: move,
            index
          };
        } else {
          moveList[moveIndex].black = {
            pge: move,
            index
          };
        }
        return moveList;
      }, []);
    }
  }
};
</script>

<style scoped>
.button {
  border: none;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0 1em;
  background: rgba(20, 20, 20, 0.77);
  color: white;
  box-shadow: 0 0 2px 2px rgba(24, 24, 24, 0.4);
  text-shadow: 0 0 1px #333;
}

.moveHistory {
  margin-bottom: 0;
  background: white;
}

.turn-number {
  font-size: 0.75em;
  color: #888;
}

.move {
  line-height: 1.5;
  font-size: calc(1em + 1vw);
  margin: 0 auto;
  font-weight: bold;
}

.move span {
  cursor: pointer;
}

.active {
  color: green;
}

.error {
  width: 160px;
  float: right;
  color: #dd5555;
}

.mb-1 {
  margin-bottom: 1em;
}
</style>
