import { render, staticRenderFns } from "./MoveHistory.vue?vue&type=template&id=3f50c488&scoped=true&"
import script from "./MoveHistory.vue?vue&type=script&lang=js&"
export * from "./MoveHistory.vue?vue&type=script&lang=js&"
import style0 from "./MoveHistory.vue?vue&type=style&index=0&id=3f50c488&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f50c488",
  null
  
)

component.options.__file = "MoveHistory.vue"
export default component.exports